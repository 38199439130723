import React from 'react'
import { toast } from 'react-hot-toast/headless'
import { Alert, AlertContent, AlertType } from './alert'

export const useAlert = () => {
  const showAlert = React.useCallback((alert: Alert, id?: string) => {
    // @ts-ignore ok
    toast(alert,{
      id
    })
  }, [])

  const showInfo = React.useCallback((alert: AlertContent, id?: string) => {
    showAlert({
      ...alert,
      type: AlertType.info,
    }, id || 'info')
  }, [])

  const showError = React.useCallback((alert: AlertContent, id?: string) => {
    showAlert({
      ...alert,
      type: AlertType.error,
    }, id || 'error')
  }, [])

  const showWarn = React.useCallback((alert: AlertContent, id?: string) => {
    showAlert({
      ...alert,
      type: AlertType.warning,
    }, id || 'warn')
  }, [])

  const showSuccess = React.useCallback((alert: AlertContent, id?: string) => {
    showAlert({
      ...alert,
      type: AlertType.success,
    }, id || 'success')
  }, [])

  // const addValidationError = React.useCallback((message: string) => {
  // 	addStatus({
  // 		type: AlertType.VALIDATIONERROR,
  // 		message,
  // 	});
  // }, []);

  // const addConnectionError = React.useCallback(() => {
  // 	setConnectionStatus({ variables: { connected: false } });
  // }, []);

  // const removeConnectionError = React.useCallback(() => {
  // 	setConnectionStatus({ variables: { connected: true } });
  // }, []);

  // const clearStatus = React.useCallback((types: AlertType[]) => {
  // 	setTimeout(() => resetStatus({ variables: { input: { types } } }), 0);
  // }, []);

  return {
    showInfo,
    showWarn,
    showError,
    showSuccess,
    // clearStatus,
    // addConnectionError,
    // removeConnectionError,
    // addValidationError,
  }
}
