import React from 'react'
import { Toast } from 'react-hot-toast/headless'
import { Transition, TransitionChild } from '@headlessui/react'
import {
  XCircleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline'
import { Status } from '~ui/Status'
import { AlertType } from './alert'

export const AlertComponent: React.FC<{ alert: Toast }> = ({ alert }) => {
  const [show, setShow] = React.useState<boolean>(true)
  React.useEffect(() => {
    setShow(alert.visible)
  }, [alert.visible])
  const handleHide = React.useCallback(() => {
    setShow(false)
  }, [])
  // @ts-ignore ok
  const { message, title, type } = alert.message || {}
  let icon = alert.icon

  if (!icon) {
    switch (type as AlertType) {
      case AlertType.error:
        icon = (
          <XCircleIcon className="h-6 w-6 text-error-400 dark:text-error-400" aria-hidden="true" />
        )
        break
      case AlertType.success:
        icon = (
          <CheckCircleIcon
            className="h-6 w-6 text-success-400 dark:text-success-400"
            aria-hidden="true"
          />
        )
        break
      case AlertType.warning:
        icon = (
          <ExclamationCircleIcon
            className="h-6 w-6 text-warn-400 dark:text-warn-400"
            aria-hidden="true"
          />
        )
        break
      case AlertType.info:
        icon = (
          <ExclamationCircleIcon
            className="h-6 w-6 text-highlight-400 dark:text-highlight-400"
            aria-hidden="true"
          />
        )
        break
      default:
        icon = null
    }
  }

  return (
    <Transition
      show={show}
      as={React.Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Status title={title} message={message} icon={icon} type={type} onHide={handleHide} />
    </Transition>
  )
}
