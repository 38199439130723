// import { Toaster, ToastIcon, alert, resolveValue } from 'react-hot-alert'
import { toast, useToaster } from 'react-hot-toast/headless'

import { AlertComponent } from './AlertComponent'

export { toast as showAlert }

export const AlertContainer = () => {
  const { toasts, handlers } = useToaster({
    position: 'top-right',
  })
  const { startPause, endPause /*, calculateOffset, updateHeight*/ } = handlers
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:p-6 z-100"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end mt-14">
        {toasts.map((alert) => (
          <AlertComponent key={alert.id} alert={alert} />
        ))}
      </div>
    </div>
  )
}
